exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academic-collaboration-js": () => import("./../../../src/pages/academic-collaboration.js" /* webpackChunkName: "component---src-pages-academic-collaboration-js" */),
  "component---src-pages-esg-impact-js": () => import("./../../../src/pages/esg-impact.js" /* webpackChunkName: "component---src-pages-esg-impact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-approach-js": () => import("./../../../src/pages/our-approach.js" /* webpackChunkName: "component---src-pages-our-approach-js" */),
  "component---src-templates-case-study-listing-js": () => import("./../../../src/templates/CaseStudyListing.js" /* webpackChunkName: "component---src-templates-case-study-listing-js" */),
  "component---src-templates-content-page-js": () => import("./../../../src/templates/ContentPage.js" /* webpackChunkName: "component---src-templates-content-page-js" */),
  "component---src-templates-customer-listing-js": () => import("./../../../src/templates/CustomerListing.js" /* webpackChunkName: "component---src-templates-customer-listing-js" */),
  "component---src-templates-district-listing-js": () => import("./../../../src/templates/DistrictListing.js" /* webpackChunkName: "component---src-templates-district-listing-js" */),
  "component---src-templates-post-detail-js": () => import("./../../../src/templates/PostDetail.js" /* webpackChunkName: "component---src-templates-post-detail-js" */),
  "component---src-templates-post-listing-js": () => import("./../../../src/templates/PostListing.js" /* webpackChunkName: "component---src-templates-post-listing-js" */),
  "component---src-templates-project-detail-js": () => import("./../../../src/templates/ProjectDetail.js" /* webpackChunkName: "component---src-templates-project-detail-js" */),
  "component---src-templates-solution-listing-js": () => import("./../../../src/templates/SolutionListing.js" /* webpackChunkName: "component---src-templates-solution-listing-js" */),
  "component---src-templates-team-detail-js": () => import("./../../../src/templates/TeamDetail.js" /* webpackChunkName: "component---src-templates-team-detail-js" */),
  "component---src-templates-team-listing-js": () => import("./../../../src/templates/TeamListing.js" /* webpackChunkName: "component---src-templates-team-listing-js" */)
}

